import { ActionButton } from '../../action-buttons';
import { ActivatedRoute } from '@angular/router';
import {
  AfterContentInit, DoCheck, EventEmitter, HostListener, ChangeDetectorRef, Component, Input, Output,
  ViewChild, KeyValueDiffers, KeyValueDiffer
} from '@angular/core';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api/selectitem';
import * as _ from 'lodash';
import * as  moment from 'moment';

import { CONSTANTS } from '../../../constants';
import { DatePipe } from '@angular/common';
import { GridOptions } from '../../../constants/grid-options';
import { LabelService } from '../../../../administration/label-management/label.service';

const CONTAINS = 'contains';
const CURRENT_PAGE = 1;
const DRAG_CSS_CLASS = 'over';
const DRAG_EFFECT_TIMEOUT = 100;
const EFFECT_ALLOWED = 'move';
const EVENT = '$event';
const ON_DRAG_OVER = 'ondragover';
const ON_DRAG_START = 'ondragstart';
const ON_DROP = 'ondrop';
const ROW_DATA = 'rowData';
const TARGET_ID = 'targetId';

@Component({
  selector: 'app-grid-wrapper',
  templateUrl: 'grid-wrapper.component.html',
  styleUrls: ['grid-wrapper.component.css']
})

/**
* @description Automatically generates a grid according to data and options given.
  DEFAULT values have been defined inside GridOptions (grid-options.ts) file
* @class GridWrapperComponent
* @param {any[]} columns (Required) Array of user defined columns. Given columns can have the following custom options:
  type: 'bit' - If selected type is a bit, it'll generate a switchInput column.
  type: 'dropdown' - If selected type is a dropdown, it'll generate a normal cell for content and a dropdown filter
  type: 'cell-dropdown' - If selected type is a dropdownColumn, it'll generate a cell with dropdown content.
  type: 'editable' - If selected type is an edit cell, it'll generate the proper hooks for that cell to become editable
  type: 'list' - If selected type is a list, it'll generate no filter for that column and will add a lookUp button inside the cell
  type: 'date' - If selected type is a date, it'll automatically set a date pipe for it to have a natural format (dd-mm-yyyy)
  type: none (not specified) - Automatically generates default column cell with data and filter.
* @param {any[]} gridData (Required) Array of data that will give form to the grid
* @param {ActionButton[]} actionButtons (Optional) Grid's action buttons
* @param {SelectItem[]} columnOptions (Optional) Defined column options for grid's column toggler
* @param {strgin} dragNdropId (Optional) Grid identifier for current grid. This is Needed for Drag and Drop to work
* @param {boolean} editable (Optional) Defines wether grid's columns can become editable or not
* @param {boolean} export (Optional) Defines if grid's export should be enabled. This shows or hides export's button.
* @param {string} exportFilename (Optional) User defined export's file name.
* @param {ActionButton[]} gridButtons (Optional) User defined buttons. Placed at the start of the grid.
* @param {boolean} hideHeaderExport (Optional) Defines if export button should be shown
* @param {boolean} hideHeaderPagination (Optional) Defines if pagination should be shown
* @param {boolean} hideHeaderGlobalFilter (Optional) Defines if global filter should be shown
* @param {number} pageLinks (Optional) Grid's number of page links to show
* @param {boolean} paginator (Optional) Enables or disables grid's pagination
* @param {string} patternEditInput (optional) Defines teh pattern in editable input.
* @param {boolean} reorderableColumns (Optional) Defines if grid's columns can be reordered or not.
* @param {boolean} resizableColumns (Optional) Defines if columns can be reisized. If enabled, grid's columns *must* be part of a col group.
* @param {boolean} responsive (Optional) Defines if grid should be responsive or static.
* @param {number} rows (Optional) Defines initial number of rows per page.
* @param {number[]} rowsPerPageOptions (Optional) Defines number of rows options for dropdown menú.
* @param {string} scrollHeight (Optional) Defines grid's scroll height.
* @param {boolean} scrollable (Optional) Defines grid's scrolling behaviour.
* @param {string} selectionMode (Optional) Defines the type of selection the grid should have (single or multiple).
* @param {boolean} showColToggler (Optional) Defines if column toggler should be shown
* @param {string} title (Optional) View's title. User defined.
* @param {boolean} wip (Optional) Defines if theres a work in progress on grid's parent
* @fires dragNdrop (Optional) Returns dragged row data and emits it to parent
* @fires editComplete (Optional) Returns cell edited data and emits it to parent
* @fires editInit (Optional) Returns cell before editing data and emits it to parent
*/
export class GridWrapperComponent implements AfterContentInit, DoCheck {
  @ViewChild('tt', { static: false }) table: Table;
  @ViewChild('grid', { static: false }) grid: any;

  @Input() columns: any[];
  @Input() gridData: any[];
  // Grid Options
  @Input() actionButtons?: ActionButton[];
  @Input() columnOptions?: SelectItem[];
  @Input() dragNdropId?: string;
  @Input() editable?: boolean;
  @Input() enableCheckbox?: boolean;
  @Input() export?: boolean;
  @Input() exportFilename?: string;
  @Input() globalFilterFields?: string[];
  @Input() gridButtons?: ActionButton[];
  @Input() pageLinks?: number;
  @Input() paginator?: boolean;
  @Input() patternEditInput?: string;
  @Input() reorderableColumns?: boolean;
  @Input() resizableColumns?: boolean;
  @Input() responsive?: boolean;
  @Input() rows?: number;
  @Input() rowsPerPageOptions?: number[];
  @Input() scrollable?: boolean;
  @Input() scrollHeight?: string;
  @Input() selectionMode?: string;
  @Input() showColToggler?: boolean;
  @Input() hideHeaderExport?: boolean;
  @Input() hideHeaderPagination?: boolean;
  @Input() hideHeaderGlobalFilter?: boolean;
  @Input() title?: string;
  @Input() wip?: boolean;
  @Output() public checkboxToggle?: EventEmitter<boolean> = new EventEmitter();
  @Output() public checkboxSelector?: EventEmitter<any> = new EventEmitter();
  @Output() public customContentSelected?: EventEmitter<any> = new EventEmitter();
  @Output() public dragNdrop?: EventEmitter<any> = new EventEmitter<any>();
  @Output() public editComplete?: EventEmitter<any> = new EventEmitter();
  @Output() public editInit?: EventEmitter<any> = new EventEmitter();
  @Output() public itemSelectedDropdown?: EventEmitter<any> = new EventEmitter();
  @Output() public rowSelected?: EventEmitter<any> = new EventEmitter();
  @Output() public selectionFields?: EventEmitter<any> = new EventEmitter();
  @Output() public unselectionFields?: EventEmitter<any> = new EventEmitter();

  public currentPage: number;
  public customData: any;
  public differ: KeyValueDiffer<string, any>;
  public display: boolean;
  public filters: Object;
  public fieldsToFilter: string[];
  public globalFilter: any;
  public isDraggable: boolean;
  public paginationCount: string;
  public selection: any[];
  public view: string;
  public lbl = {
    cleanFilters: this.labelService.labelText('cleanFiltersButton'),
    columnsToggler: this.labelService.labelText('columnsToggler'),
    doorSelection: this.labelService.labelText('doorSelection'),
    errorProductAdded: this.labelService.labelText('errorProductAdded'),
    export: this.labelService.labelText('exportButton'),
    exportSelection: this.labelService.labelText('exportSelectionButton'),
    filterPlaceholder: this.labelService.labelText('headerFilter'),
    globalFilter: this.labelService.labelText('globalFilter'),
    no: this.labelService.labelText('selectOptionNoForm'),
    paginationCount: this.labelService.labelText('paginationCount'),
    successProductAdded: this.labelService.labelText('successProductAdded'),
    yes: this.labelService.labelText('selectOptionYesForm'),
    messages: {
      errorTitle: this.labelService.labelText('errorTitle')
    }
  };

  constructor(private cdr: ChangeDetectorRef,
    private labelService: LabelService,
    private route: ActivatedRoute,
    private differs: KeyValueDiffers,
    private datePipe: DatePipe) {
    this.currentPage = CURRENT_PAGE;
    this.differ = this.differs.find({}).create();
    this.display = false;
    this.filters = {};
    this.gridInit();
    this.isDraggable = false;
    this.paginationCount = this.lbl.paginationCount;
    this.selection = [];
    this.view = this.route.routeConfig.component.name;
  }

  /**
   * @description This is called after components external content has been initialized.
   * @return {void}
   */
  public ngAfterContentInit(): void {
    this.cdr.detectChanges();
    this.isDraggable = (_.isUndefined(this.dragNdropId) || _.isUndefined(this.dragNdrop)) ? false : true;
    this.initializeFilters();
  }

  /**
   * @description DoCheck lyceCycleHook function
   * @return {void}
   */
  public ngDoCheck(): void {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        if (_.isEqual(item.key, 'gridData') && !_.isEqual(item.previousValue, item.currentValue)) {
          this.selection = [];
          this.validateDateFormat();
        }
      });
    }
  }

  /**
   * @description Selected row method & emit the selected row by user.
   * @param {any} event Event Table.
   * @return {void}
   */
  public onRowSelect(event: any): void {
    this.selectionFields.emit(event.data);
  }

  /**
   * @description Un-Selected row method & emit the un-selected row by user.
   * @param {any} event Event Table.
   * @return {void}
   */
  public onRowUnselect(event: any): void {
    this.unselectionFields.emit(event.data);
  }

  /**
   * @description emits the row edit complete event
   * @param {any} event Event Table.
   * @return {void}
   */
  public onEditComplete(event: any): void {
    this.editComplete.emit(event.data);
  }

  /**
   * @description emits the row edit init event
   * @param {any} event Event Table.
   * @return {void}
   */
  public onEditInit(event: any): void {
    this.editInit.emit(event);
  }

  /**
   * @description Selected drop-down method and issue of the value selected by the user.
   * @param {any} event Event Table.
   * @return {void}
   */
  public selectedOption(event: any): void {
    this.itemSelectedDropdown.emit(event);
  }

  /**
   * @description Clear grid's filters
   * @return {void}
   */
  public clearFilters(): void {
    if (this.table.hasFilter) {
      this.globalFilter = null;
      this.table.filterGlobal(null, CONTAINS);
      this.filters = {};
      this.table.reset();
    }
    this.selection = [];
  }

  /**
   * @description Add the property filter to a specif field and with specific matchMode
   * @param {any} value Field's value received
   * @param {any} column Field wich will be filtering.
   * @param {any} matchMode filterMatchMode received
   * @return {void}
   */
  public filter(value?: any, column?: any, matchMode?: any): void {
    let fill: string;
    fill = column.subfield ? column.field + '.' + column.subfield : column.field;
    if (!matchMode) {
      matchMode = column.filterMatchMode;
    }
    this.table.filter(value, fill, matchMode);
  }

  /**
   * @description Remove filter's input.
   * @return {void}
   */
  public removeFilters(): void {
    this.table.reset();
  }

  /**
   * @description Gets current information when table is paged.
   * @param {any} event Event fired
   * @return {void}
   */
  public onPageChange(event: any): void {
    this.currentPage = (event.first / event.rows) + 1;
  }

  /**
   * @description Get row data selected.
   * @param {ShipmentOrder} shipment single row selected.
   * @return {void}
   */
  public selectRowData(data: any): void {
    this.rowSelected.emit(data);
  }

  /**
   * @description Header selected component.
   * @param {event} any Event Table.
   * @return {void}
   */
  public headerCheckboxToggle(event: any): void {
    this.checkboxToggle.emit(event.checked);
  }

  /**
   * @description Custom content selected component.
   * @param {event} any Event Table.
   * @return {void}
   */
  public selectedCustomContent(data: any): void {
    this.customContentSelected.emit(data);
  }

  /**
   * @description Initialize the fields that are going to be usen in the primeng table
   * @returns {void}
   */
  public initializeFilters(): void {
    this.fieldsToFilter = [];
    _.forEach(this.columns, (col: any) => {
      if (col.subfield) {
        this.fieldsToFilter.push(col.field + '.' + col.subfield);
      } else {
        this.fieldsToFilter.push(col.field);
      }
    });
  }

  /**
   * @description Initializes grid's options and configuration
   * @return {void}
   */
  private gridInit(): void {
    this.actionButtons = _.isUndefined(this.actionButtons) ? GridOptions.ACTION_BUTTONS : this.actionButtons;
    this.editable = _.isUndefined(this.editable) ? GridOptions.EDITABLE : this.editable;
    this.enableCheckbox = _.isUndefined(this.enableCheckbox) ? GridOptions.ENABLE_CHECKBOX : this.enableCheckbox;
    this.export = _.isUndefined(this.export) ? GridOptions.EXPORT : this.export;
    this.exportFilename = _.isUndefined(this.exportFilename) ? GridOptions.EXPORT_FILENAME : this.exportFilename;
    this.gridButtons = _.isUndefined(this.gridButtons) ? GridOptions.GRID_BUTTONS : this.gridButtons;
    this.pageLinks = _.isUndefined(this.pageLinks) ? GridOptions.PAGE_LINKS : this.pageLinks;
    this.paginator = _.isUndefined(this.paginator) ? GridOptions.PAGINATOR : this.paginator;
    this.patternEditInput = _.isUndefined(this.patternEditInput) ? '' : this.patternEditInput;
    this.reorderableColumns = _.isUndefined(this.reorderableColumns) ? GridOptions.REORDERABLE_COLUMNS : this.reorderableColumns;
    this.resizableColumns = _.isUndefined(this.resizableColumns) ? GridOptions.RESIZABLE_COLUMNS : this.resizableColumns;
    this.responsive = _.isUndefined(this.responsive) ? GridOptions.RESPONSIVE : this.responsive;
    this.rows = _.isUndefined(this.rows) ? GridOptions.ROWS : this.rows;
    this.rowsPerPageOptions = _.isUndefined(this.rowsPerPageOptions) ? GridOptions.ROWS_PER_PAGE_OPTIONS : this.rowsPerPageOptions;
    this.scrollable = _.isUndefined(this.scrollable) ? GridOptions.SCROLLABLE : this.scrollable;
    this.scrollHeight = _.isUndefined(this.scrollHeight) ? GridOptions.SCROLL_HEIGHT : this.scrollHeight;
    this.selectionMode = _.isUndefined(this.selectionMode) ? GridOptions.SELECTION_MODE : this.selectionMode;
    this.showColToggler = _.isUndefined(this.showColToggler) ? GridOptions.SHOW_COL_TOGGLER : this.showColToggler;
    this.hideHeaderExport = _.isUndefined(this.hideHeaderExport) ? GridOptions.GRID_HEADER : this.hideHeaderExport;
    this.hideHeaderPagination = _.isUndefined(this.hideHeaderPagination) ? GridOptions.GRID_HEADER : this.hideHeaderPagination;
    this.hideHeaderGlobalFilter = _.isUndefined(this.hideHeaderGlobalFilter) ? GridOptions.GRID_HEADER : this.hideHeaderGlobalFilter;
    this.wip = _.isUndefined(this.wip) ? GridOptions.WIP : this.wip;
  }

  /**
   * @description Listens for drag start event on drag and drop
   */
  @HostListener(ON_DRAG_START, [EVENT])
  dragstart(ev: any) {
    ev.dataTransfer.setData(ROW_DATA, ev.target.dataset.row);
    ev.dataTransfer.setData(TARGET_ID, this.dragNdropId);
    ev.dataTransfer.effectAllowed = EFFECT_ALLOWED;

    let el = this.grid.nativeElement;
    el.classList.add(DRAG_CSS_CLASS);

    setTimeout(() => {
      el.classList.remove(DRAG_CSS_CLASS);
    }, DRAG_EFFECT_TIMEOUT);
  }

  /**
   * @description Listens for the on drop event on drag and drop
   */
  @HostListener(ON_DROP, [EVENT])
  drop(ev: any) {
    ev.preventDefault();
    let data = ev.dataTransfer.getData(ROW_DATA);
    let targetId = ev.dataTransfer.getData(TARGET_ID);
    let el = this.grid.nativeElement;
    el.classList.add(DRAG_CSS_CLASS);

    setTimeout(() => {
      el.classList.remove(DRAG_CSS_CLASS);
    }, DRAG_EFFECT_TIMEOUT);

    // If target's grid id is different from this grid, means a successful drag and drop event took place so it should emit data to parent
    if (targetId !== this.dragNdropId) {
      this.dragNdrop.emit(JSON.parse(data));
    }
  }

  /**
   * @description Custom export value.
   * @param {none}
   */
  public customAllExport(): void {
    let customElementAllId = document.getElementById('export-button');
    this.processData(this.gridData, customElementAllId);
  }

  /**
   * @description Custom export value.
   * @param {none}
   */
  public customSingleExport(): void {
    let customElementSingleId = document.getElementById('export-single-button');
    this.processData(this.selection, customElementSingleId);
  }

  /**
   * @description process data to split comma & space
   * @return {Promise<any>}
   */
  private processData(data: any[], elementId: any): void {
    if (!_.isEmpty(data)) {
      this.customData = '';

      this.customData = this.columns.map(col => col.header).join(',') + '\n';
      _.forEach(data, (item: any) => {
        _.forEach(this.columns, (col: any) => {
          if (item[col.field] && !_.isUndefined(item[col.field][col.subfield])) {
            this.customData += item[col.field][col.subfield] + ',';
          } else {
            if (_.isEqual(col.type, 'bit')) {
              this.customData += (item[col.field] === true ? String('Si' + ',') : String('No' + ','));
            } else if (_.isEqual(col.type, 'date')) {
              this.customData += (moment(item[col.field], 'DD-MM-YYYY').utc().format('DD/MMMM/YYYY')) + ',';
            } else if (_.isEqual(col.type, 'list')) {
              if (col.exportField) {
                _.forEach(item[col.field], (subItem: any, index: number) => {
                  if (!index) {
                    this.customData += '\"' + subItem[col.exportField];
                  } else {
                    this.customData += ', ' + subItem[col.exportField];
                  }
                });
                this.customData += '\",';
              } else {
                this.customData += ',';
              }
            } else {
              this.customData += '\"' + item[col.field] + '\"' + ',';
            }
          }
        });
        this.customData += '\n';
      });
      this.generateCSV(this.customData, this.exportFilename + '.csv', elementId);
    }
  }

  /**
   * @description generates a csv given the data, filename and <a> link reference
   * @param {any} data data representing the csv
   * @param {string} filename name of the file to download
   * @param {HTMLElement} reference a dom reference of an <a> tag to emulate the click action
   */
  public generateCSV(data: any, filename: string, reference: HTMLElement) {
    data = CONSTANTS.BYTE_ORDER_MARK + data;
    const blob: Blob = new Blob([data], { type: 'text/csv;charset=utf-8;'});
    const url: string = URL.createObjectURL(blob);
    reference.setAttribute('href', url);
    reference.setAttribute('download', filename);
    reference.click();
  }

  /**
   * @description Emit the selected row in the checkbox
   * @param {*} data
   * @returns {void}
   */
  public emitCheckboxSelection(data: any): void {
    this.checkboxSelector.emit(data);
  }

  /**
   * @description Listens for the on drag over event on drag and drop
   */
  @HostListener(ON_DRAG_OVER, [EVENT])
  dragover(ev: any) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = EFFECT_ALLOWED;
  }

  /**
   * @description Transforms the dates in the grid in order to can be filtered
   * @returns {void}
   */
  public validateDateFormat(): void {
    let dateColumns = _.reduce(this.columns, (accumulator: any[], column: any) => {
      if (_.isEqual(column.type, CONSTANTS.DATE.toLowerCase())) {
        accumulator.push(column);
      }
      return accumulator;
    }, []);

    for (let data of this.gridData) {
      for (let col of  dateColumns) {
        this.transformDataField(data, col, !_.isUndefined(col.subfield));
      }
    }
  }

  /**
   * @description Update the data field according is the object haa subfield or not
   * @param {*} data The cureent object in the gridData
   * @param {*} col The current col to validate
   * @param {boolean} isSubfield Variable to know if the data has subfield
   * @returns {void}
   */
  public transformDataField(data: any, col: any, isSubfield: boolean): void {
    if (isSubfield) {
        data[col.field][col.subfield] = this.datePipe.transform(new Date(data[col.field][col.subfield]),
         CONSTANTS.PIPE_DATE_FORMAT, CONSTANTS.PIPE_TIME_ZONE);
    } else {
      data[col.field] = this.datePipe.transform(new Date(data[col.field]),
         CONSTANTS.PIPE_DATE_FORMAT, CONSTANTS.PIPE_TIME_ZONE);
    }
  }
}
