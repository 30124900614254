import { WepConfig } from '../env-config/wep-config.interface';

export const ENVIRONMENT: WepConfig = {
  PRODUCTION: true,
  API: 'https://ccudev.eastus.cloudapp.azure.com/api/core/v1/',
  MSGTIME: '2000',
  DISPLAYTIME: '100',
  ALLOWEDROUTES: 'allowed_routes',
  OFFLINE_ERROR: 'OFFLINE_ERROR',
  CLIENTID: '5f67292d76431262648c5b5533db2ade',
  CLIENTSECRET: '5ebe2294ecd0e0f08eab7690d2a6ee69',
  RFCLIENTID: 'Y9aLkqQyTHo26t4ja9DZiuqoxvvM3v78',
  RFCLIENTSECRET: '7agv9ICHTlHPqNNRmecXWAf1AWFsonAz',
  RF: 'ws://ccudev.eastus.cloudapp.azure.com/api/core/v1/',
  APIPLANNING: 'https://ccudev.eastus.cloudapp.azure.com/api/planning/v1/',
  API_INTEGRATOR: 'https://ccudev.eastus.cloudapp.azure.com/api/integrator/v1/',
  googleMapsKey: 'AIzaSyB4YnyugZLz3nwGwJnojTj6q_B7BSej-tQ',
  APIPRINTER: 'http://api-printer',
  APPINSIGHTS_INSTRUMENTATIONKEY: '011b50d2-cac2-4815-b409-301574654e8f'
};

